import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Footer = () => {

  const [latestBlogs, setLatestBlogs] = useState([]);
  const [latestBlogImages, setLatestBlogImages] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [visibleCategories, setVisibleCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
      axios.get(`${process.env.REACT_APP_BLOG_URL}footer`)
          .then(response => {
              if (response.data.ResponseCode === 1) {
                  setLatestBlogs(response.data.ResponseData.latest_blogs);

                  setLatestBlogImages(response.data.ResponseData.latest_blog_images);

                  setFollowers(response.data.ResponseData.followers);
              } else {
                  console.error('Error fetching footer data:', response.data.ResponseText);
              }
          })
          .catch(error => {
              console.error('Error fetching footer data:', error);
          });
  }, []);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BLOG_URL}footer`)
        .then(response => {
            if (response.data.ResponseCode === 1) {
                const fetchedCategories = response.data.ResponseData.categories;

                const initialVisibleCategories = fetchedCategories.slice(0, 20);
                
                setCategories(fetchedCategories);
                setVisibleCategories(initialVisibleCategories);
                if (fetchedCategories.length > 20) {
                    setShowMore(true);
                }
            } else {
                console.error('Error fetching categories:', response.data.ResponseText);
            }
        })
        .catch(error => {
            console.error('Error fetching categories:', error);
        });
}, []);
const handleShowMore = () => {
  const nextVisibleCategories = categories.slice(visibleCategories.length, visibleCategories.length + 20);
  setVisibleCategories([...visibleCategories, ...nextVisibleCategories]);
  if (categories.length <= visibleCategories.length + 20) {
      setShowMore(false);
  }
  navigate('/category');
};
const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
}

  return (
   <>
   <section>
  <div>
  {/* Footer Start */}
  <div className="container-fluid bg-dark pt-5 px-sm-3 px-md-5 mt-5">
    <div className="row py-4">
      <div className="col-lg-3 col-md-6 mb-5">
        <h5 className="mb-4 text-white text-uppercase font-weight-bold">Get In Touch</h5>
        <p className="font-weight-medium"><i className="fa fa-map-marker-alt mr-2" />123 Street, New York, USA</p>
        <p className="font-weight-medium"><i className="fa fa-phone-alt mr-2" />+012 345 67890</p>
        <p className="font-weight-medium"><i className="fa fa-envelope mr-2" />info@example.com</p>
        <h6 className="mt-4 mb-3 text-white text-uppercase font-weight-bold">Follow Us</h6>
        <div className="d-flex justify-content-start">
            {followers.map(follower => (
                <a key={follower.id} className="btn btn-lg btn-secondary btn-lg-square mr-2" href={follower.url}>
                    <img src={follower.image} alt="" />
                </a>
            ))}
        </div>
      </div>
      <div className="col-lg-3 col-md-6 mb-5">
        <h5 className="mb-4 text-white text-uppercase font-weight-bold">Popular News</h5>
        {latestBlogs.map(blog => (
                <div key={blog.id} className="mb-3">
                    <div className="mb-2">
                        <a className="badge badge-primary text-uppercase font-weight-semi-bold p-1 mr-2" href="#">Business</a>
                        <a className="text-body" href="#"><small>{new Date(blog.created_at).toDateString()}</small></a>
                    </div>
                    <a className="small text-body text-uppercase font-weight-medium" href="#">{blog.shortdescription}</a>
                </div>
            ))}
      </div>
      <div className="col-lg-3 col-md-6 mb-5">
            <h5 className="mb-4 text-white text-uppercase font-weight-bold">Categories</h5>
            <div className="m-n1">
                {visibleCategories.map(category => (
                    <a key={category.id} onClick={() => { navigate('/category'); scrollToTop(); }}className="btn btn-sm btn-secondary m-1">{category.name}</a>
                ))}
                {showMore && (
                    <button className="btn btn-sm btn-secondary m-1" onClick={handleShowMore}>Show More</button>
                )}
            </div>
        </div>
      <div className="col-lg-3 col-md-6 mb-5">
            <h5 className="mb-4 text-white text-uppercase font-weight-bold">Flickr Photos</h5>
            <div className="row">
                {latestBlogImages.map(image => (
                    <div key={image.id} className="col-4 mb-3">
                        <a href="#"><img className="w-100" src={image.image} alt="" /></a>
                    </div>
                ))}
            </div>
        </div>
    </div>
  </div>
  <div className="container-fluid py-4 px-sm-3 px-md-5" style={{background: '#111111'}}>
    <p className="m-0 text-center">© <a href="#">Your Site Name</a>. All Rights Reserved. 
      {/*/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. *** /*/}
      Design by <a href="https://htmlcodex.com">HTML Codex</a><br />
      Distributed by <a href="https://themewagon.com">ThemeWagon</a>
    </p>
  </div>
  {/* Footer End */}
</div>

   </section>
   </>
  )
}

export default Footer
