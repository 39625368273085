import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Adversitement } from '../Home/Adversitement';
import { TrandingNews } from '../Home/TrandingNews';
import { NewsLetter } from '../Home/NewsLetter';
import { Tags } from '../Home/Tags';
import Follow from '../Home/Follow/Follow';
import Comment from './Comment/Comment';
import { CommentList } from './CommentList';
// Import CommentList without curly braces

const ProductDetail = () => {
  const [blogData, setBlogData] = useState(null);
  const { slug } = useParams();
  const [comments, setComments] = useState([]); 
  const blogId = localStorage.getItem('currentBlogId')


  useEffect(() => {
    async function fetchBlogData() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BLOG_URL}blog-detail/${slug}`);
        if (response.data.ResponseCode === 1) {
          setBlogData(response.data.ResponseData);
          setComments(response.data.comments);
          // Set blog ID in local storage
          localStorage.setItem('currentBlogId', response.data.ResponseData.id);
        } else {
          console.error('Error fetching blog data:', response.data.ResponseText);
        }
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    }

    fetchBlogData();
  }, [slug]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };

  return (
    <>
      {/* <div className="container-fluid px-xl-5 mt-5">
        <div className="row">
          <div className="col-lg-8">
            {blogData && (
              <div className="position-relative mb-3">
                <img className="img-fluid w-100" src={blogData.image} style={{ objectFit: 'cover' }} />
                <div className="bg-white border border-top-0 p-4">
                  <div className="mb-3">
                    <a className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2" href>{blogData.category_name}</a>
                    <a className="text-body" href>{formatDate(blogData.created_at)}</a>
                  </div>
                  <h1 className="mb-3 text-secondary text-uppercase font-weight-bold">{blogData.shortdescription}</h1>
                  <div dangerouslySetInnerHTML={{ __html: blogData.description }} />
                </div>
              </div>
            )}
            <CommentList comments={comments} />
            <Comment  blogSlug={slug} blogId={blogId} />
          </div>
          <div className="col-lg-4">
            <Follow />
            <Adversitement />
            <TrandingNews />
            <NewsLetter />
            <Tags />
          </div>
        </div>
      </div> */}
      <div className="container-fluid px-xl-5 mt-5">
        <div className="row">
          <div className="col-lg-8">
            {blogData && (
              <div className="position-relative mb-3">
                {/* Set id for image element */}
                <div style={{ maxWidth: '100%',height: '600px'}}>

                <img id="blogImage" className="w-100 h-100 " src={blogData.image} alt style={{ objectFit: 'cover' }} />
                </div>
                <div className="bg-white border border-top-0 p-4">
                  <div className="mb-3">
                    <a className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2" href>{blogData.category_name}</a>
                    <a className="text-body" href>{formatDate(blogData.created_at)}</a>
                  </div>
                  <h1 className="mb-3 text-secondary text-uppercase font-weight-bold">{blogData.shortdescription}</h1>
                  <div dangerouslySetInnerHTML={{ __html: blogData.description }} />
                </div>
              </div>
            )}
            <CommentList comments={comments} />
            <Comment  blogSlug={slug} blogId={blogId} />
          </div>
          <div className="col-lg-4">
            <Follow />
            <Adversitement />
            <TrandingNews />
            <NewsLetter />
            <Tags />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetail;
