import React from 'react'
import LatestNews from './LetestNews/LatestNews'
import FeaturedNews from './FeaturedNews/FeaturedNews'
import MainCaresouel from './MainCaresouel/MainCaresouel'
import BreakingNews from './BreakingNews/BreakingNews'

const Home = () => {
  return (
 <>
 <MainCaresouel />
 <BreakingNews />
 <FeaturedNews />
 <LatestNews />
 </>
  )
}

export default Home
