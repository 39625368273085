import React, { useState } from 'react'
import Follow from '../Home/Follow/Follow'
import { NewsLetter } from '../Home/NewsLetter';
import axios from 'axios';


const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        sub: '',
        msg: ''
    });
    const [responseMessage, setResponseMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_BLOG_URL}contact-add`, formData);
            if (response.data.ResponseCode === 1) {
                setResponseMessage('Message sent successfully');
            } else {
                setResponseMessage('Failed to send message');
            }
        } catch (error) {
            console.error('Error submitting contact form:', error);
            setResponseMessage('An error occurred while sending the message');
        }
    };

    return (
        <>
            <div className="container-fluid mt-5 pt-3">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="section-title mb-0">
                            <h4 className="m-0 text-uppercase font-weight-bold">Contact Us For Any Queries</h4>
                        </div>
                        <div className="bg-white border border-top-0 p-4 mb-3">
                            <div className="mb-4">
                                <h6 className="text-uppercase font-weight-bold">Contact Info</h6>
                                <p className="mb-4">The contact form is currently inactive. Get a functional and working contact form with Ajax &amp; PHP in a few minutes. Just copy and paste the files, add a little code and you're done. <a href="https://htmlcodex.com/contact-form">Download Now</a>.</p>
                                <div className="mb-3">
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-map-marker-alt text-primary mr-2" />
                                        <h6 className="font-weight-bold mb-0">Our Office</h6>
                                    </div>
                                    <p className="m-0">123 Street, New York, USA</p>
                                </div>
                                <div className="mb-3">
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-envelope-open text-primary mr-2" />
                                        <h6 className="font-weight-bold mb-0">Email Us</h6>
                                    </div>
                                    <p className="m-0">info@example.com</p>
                                </div>
                                <div className="mb-3">
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-phone-alt text-primary mr-2" />
                                        <h6 className="font-weight-bold mb-0">Call Us</h6>
                                    </div>
                                    <p className="m-0">+012 345 6789</p>
                                </div>
                            </div>
                            <h6 className="text-uppercase font-weight-bold mb-3">Contact Us</h6>
                            <form onSubmit={handleSubmit}>
            <div className="form-row">
                <div className="col-md-6">
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control p-4"
                            placeholder="Your Name"
                            required
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <input
                            type="email"
                            className="form-control p-4"
                            placeholder="Your Email"
                            required
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <input
                    type="text"
                    className="form-control p-4"
                    placeholder="Subject"
                    required
                    name="sub"
                    value={formData.sub}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <textarea
                    className="form-control"
                    rows={4}
                    placeholder="Message"
                    required
                    name="msg"
                    value={formData.msg}
                    onChange={handleChange}
                />
            </div>
            <div>
                <button className="btn btn-primary font-weight-semi-bold px-4" style={{ height: 50 }} type="submit">Send Message</button>
            </div>
            {responseMessage && <p>{responseMessage}</p>}
        </form>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <Follow />
                        <NewsLetter />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact
