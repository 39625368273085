import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MiddleBar from '../MiddleBar/MiddleBar';

const Navbar = () => {
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BLOG_URL}home-data`)
      .then(response => {
        const { Menu } = response.data.ResponseData;
        setMenuItems(Menu);
      })
      .catch(error => {
        console.error('Error fetching menu items:', error);
      });
    console.log(menuItems);
  }, []);

  const handleMenuItemClick = (categoryId) => {
    navigate(`/category/${categoryId}`);
  };

  return (
    <div className="container-fluid">
      <div className="row align-items-center bg-white py-md-0 py-sm-0 px-lg-5 blackbg">
        <div className="col-lg-3">
          <a href="/" className="navbar-brand p-0 d-none d-lg-block">
            <h1 className="m-0 display-4 text-uppercase text-primary">Biz<span className="text-secondary font-weight-normal">News</span></h1>
          </a>
        </div>
        <div className='col-lg-6'>
          <nav className="navbar navbar-expand-lg navbar-dark py-2 py-lg-0 px-lg-5">
            <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse justify-content-between px-lg-3 bg-white" id="navbarCollapse">
              <div className="navbar-nav mr-auto py-0">
                {/* {menuItems.map(menuItem => (
                  <a key={menuItem.id} onClick={() => handleMenuItemClick(menuItem.id)} className="nav-item nav-link pointer">{menuItem.name}</a>
                ))} */}
                 {menuItems && menuItems.map(menuItem => (
                  <a key={menuItem.id} onClick={() => handleMenuItemClick(menuItem.id)} className="nav-item nav-link pointer">{menuItem.name}</a>
                ))}
              </div>
            </div>
          </nav>
        </div>
        <div className='col-lg-3'>
          <MiddleBar />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
