import React, { useEffect, useState } from 'react'
import Adversitement from '../Adversitement/Adversitement'
// import FollowUs from '../FollowUs/FollowUs'
import TrandingNews from '../TrandingNews/TrandingNews'
import NewsLetter from '../NewsLetter/NewsLetter'
import Tags from '../Tags/Tags'
import Follow from '../Follow/Follow';
import { useNavigate } from 'react-router-dom'

const LatestNews = () => {
  const [latestData, setLatestData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [feedName, setFeedName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLatestBlogs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BLOG_URL}home-data`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        console.log('API Response:', responseData);

        if (
          responseData.ResponseData &&
          responseData.ResponseData.blog &&
          responseData.ResponseData.blog.length > 0
        ) {
          const latestBlog = responseData.ResponseData.blog.find(blog => blog.feedposition === 2);
          console.log('Latest Blog:', latestBlog);

          if (latestBlog) {
            setLatestData(Object.values(latestBlog.feedwiseblog));
            setFeedName(latestBlog.feedname);
          } else {
            throw new Error('Latest blog data not found');
          }
        } else {
          throw new Error('Blog data not found or empty');
        }

        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchLatestBlogs();
  }, []);

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  // if (error) {
  //   return <div>Error: {error.message}</div>;
  // }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };

  return (
    <>

      {/* News With Sidebar Start */}

      <div className="container-fluid px-xl-5">
        <div className="row">
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12">
                <div className="section-title">
                  <h4 className="m-0 text-uppercase font-weight-bold">{feedName}</h4>
                  <a className="text-secondary font-weight-medium text-decoration-none" href="#">View All</a>
                </div>
              </div>
              {latestData.map((blog, index) => (
                <div key={blog.id} className={`col-lg-6 ${index % 8 < 4 ? 'mb-3' : ''}`}>
                  <div className={index % 8 < 4 ? "position-relative" : "d-flex align-items-center "} style={{ minHeight: index % 8 < 4 ? '' : 110 }}>
                    {index % 8 < 4 ? (
                      <>
                        <div style={{ width: '100%', height: '400px' }}>
                          <img className="h-100" src={blog.image} alt={blog.title} style={{ objectFit: 'cover' }} />
                        </div>
                        <div className="bg-white  p-4 w-100" style={{ height: '250px' }}>
                          <div className="mb-2">
                            <a className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2" href={blog.categoryLink}>{blog.category_name}</a>
                            <a className="text-body" href="#"><small>{formatDate(blog.created_at)}</small></a>
                          </div>
                          <a className="h4 d-block mb-3 text-secondary text-uppercase font-weight-bold pointer" onClick={() => { navigate(`/blogdetail/${blog.slug}`); scrollToTop(); }}>
                          {blog.shortdescription.length > 35 ? `${blog.shortdescription.slice(0, 35)}...` : blog.shortdescription}
                          </a>
                          <div dangerouslySetInnerHTML={{ __html: `${blog.description.slice(0, 300)}${blog.description.length > 300 ? '...' : ''}` }} />
                        </div>
                        <div className="d-flex justify-content-between bg-white border-top mt-auto p-4">
                          <div className="d-flex align-items-center">
                            <img className="rounded-circle mr-2" src="../assets/img/user.jpg" style={{ width: 25, height: 25 }} alt />
                            <small>John Doe</small>
                          </div>
                          <div className="d-flex align-items-center">
                            {/* <small className="ml-3"><i className="far fa-eye mr-2" />12345</small> */}
                            <small className="ml-3"><i className="far fa-comment mr-2" />{blog.comments_count}</small>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='col-lg-12'>
                          <div className="d-flex align-items-center bg-white mb-3" style={{ height: 110 }}>
                            <div style={{ width: '200px', height: '100px' }}>
                              <img src={blog.image} style={{ objectFit: 'cover', height: '100%', width: '100%' }} />
                            </div>                            <div className=" h-100 px-3 d-flex flex-column justify-content-center  border-left-0">
                              <div className="mb-2">
                                <a className="badge badge-primary text-uppercase font-weight-semi-bold p-1 mr-2" href>{blog.category_name}</a>
                                <a className="text-body" href><small>{formatDate(blog.created_at)}</small></a>
                              </div>
                              <a onClick={() => { navigate(`/blogdetail/${blog.slug}`); scrollToTop(); }} className="h6 m-0 text-secondary text-uppercase  pointer"  > {blog.shortdescription.length > 35 ? `${blog.shortdescription.slice(0, 35)}...` : blog.shortdescription}</a>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-4">
            <Follow />
            <Adversitement />
            <TrandingNews />
            <NewsLetter />
            <Tags />
          </div>
        </div>
      </div>
      {/* News With Sidebar End */}


    </>
  )
}

export default LatestNews
